import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';

const drawerWidth = 360;

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  icon: {
    img: {
      margin: 'auto',
    },
  },
  toolbarButtons: {
    marginLeft: 'auto',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  leftButton: {
    marginLeft: 12,
    marginRight: 20,
    zIndex: 10,
  },
  rightButton: {
    marginLeft: 12,
    marginRight: 20,
    zIndex: 10,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    overflowX: 'hidden',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  bigAvatar: {
    borderStyle: 'solid',
    borderWidth: '3px',
    borderColor: '#2196f3',
    width: 100,
    height: 100,
  },
  socialIcon: {
    '&:hover': {
      borderRadius: '100px',
      backgroundClip: 'content-box',
      backgroundColor: '#B0BEC5',
    },
  },
});

class PersistentDrawerLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      scrollY: 0,
      width: window.innerWidth,
      top: false,
      dialogOpen: false,
      searchName: '',
    };
  }

  /**
   * For now I call the same function in both lifecycle hooks because the state won't always be set otherwise because of the order of the components mounting
   */
  componentDidMount() {
    window.addEventListener('scroll', this.updateScrollPosition.bind(this));
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateScrollPosition.bind(this));
    window.removeEventListener(
      'resize',
      this.updateWindowDimensions.bind(this)
    );
  }

  updateScrollPosition() {
    this.setState({ scrollY: window.scrollY });
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, theme } = this.props;
    const { open } = this.state;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          style={{
            opacity: this.state.scrollY > 0 ? 0.8 : 1,
            WebkitTransition:
              'opacity 1s ease, margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,width 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            transition:
              'opacity 1s ease, margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,width 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            transitionDelay: 'opacity 0.3s',
          }}
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar disableGutters={!open}>
            <Button
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.leftButton, open && classes.hide)}
            >
              Hints
            </Button>
            <div
              style={{
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  width: window.innerWidth < 500 ? '38px' : '300px',
                  position: 'absolute',
                  left: '50%',
                  top: '100%',
                  transform: 'translate(-50%, -60%)',
                  overflow: 'hidden',
                  WebkitTransition: '1s',
                  transition: '1s',
                }}
              >
                <img
                  style={{
                    height: '300px',
                    WebkitTransition: '1s',
                    transition: '1s',
                    cursor: 'pointer',
                  }}
                  alt="Games for your Group"
                  src="/logo.svg"
                  onClick={() => window.scroll(0, 0)}
                />
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <br />
          <List>
            <Divider />
            <ListItem>
              <Typography variant="h6" component="h6">
                Useful Hints
              </Typography>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="The application can only search a user by one of their steam identifiers" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Identifiers can be the user's actual steam name, their unique id, or the URL of their profile" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <React.Fragment>
                    Examples of identifiers can include:
                    <Divider />
                    thiccjoe https://steamcommunity.com/id/thiccjoe
                    76561198091548059
                    https://steamcommunity.com/profiles/76561198091548059
                  </React.Fragment>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Sometimes steam does not use your steam name to identify your account.  Eg. If you use the name 'Joe', it is impossible to identify our account with that alone." />
            </ListItem>
            <ListItem>
              <ListItemText primary="If you cannot find your account using your steam name, or the account you find is not yours, the easiest thing to do is to find your account on the steam community website and copy and paste the URl of your account into the search box for a guaranteed find." />
            </ListItem>
            <ListItem>
              <ListItemText primary="This tool can only show games from public profiles. Make sure your and others' profiles which you want to match are all public." />
            </ListItem>
          </List>
          <br />
          <Divider />
          <List>
            <div className={classes.row}>
              <Avatar
                alt="AS"
                src={require('./ashtonspina.jpg')}
                className={classes.bigAvatar}
              />
            </div>
            <div className={classes.row}>
              <Typography variant="h5" component="h3">
                Ashton Spina
              </Typography>
            </div>
            <a
              className={classes.row}
              href="https://ashtonspina.com"
              style={{ textDecoration: 'none', color: 'black' }}
            >
              <Button variant="outlined">About the Creator</Button>
            </a>
          </List>
          <br />
        </Drawer>
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          {this.props.children}
        </main>
      </div>
    );
  }
}

PersistentDrawerLeft.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withRouter(
  withStyles(styles, { withTheme: true })((props) => (
    <PersistentDrawerLeft {...props} />
  ))
);
