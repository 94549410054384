import React, { Component } from 'react';
import PersistentDrawerLeft from './components/ToolbarDrawer';
import NoMatch from './components/NoMatch';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import { BrowserRouter, Route } from 'react-router-dom';
import SwitchWithSlide from './SwitchWithSlide';
import SteamFriendFinder from './pages/SteamFriendFinder';
import * as ReactGA from 'react-ga';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#444444',
    },
    secondary: {
      main: '#00d1cd',
    },
    success: green,
  },
  typography: {
    useNextVariants: true,
  },
});

class App extends Component {
  componentDidMount() {
    ReactGA.initialize('UA-131177225-2');
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidUpdate() {
    ReactGA.initialize('UA-131177225-2');
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <MuiThemeProvider theme={theme}>
            <header>
              <PersistentDrawerLeft>
                <SwitchWithSlide
                  updateStep={(...currentStep) =>
                    this.setState({ currentStep })
                  }
                >
                  <Route
                    exact
                    path="/"
                    render={(props) => <SteamFriendFinder />}
                  />
                  <Route component={NoMatch} />
                </SwitchWithSlide>
              </PersistentDrawerLeft>
            </header>
          </MuiThemeProvider>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
